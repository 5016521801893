import { Button } from "reactstrap";
import { AppTitle } from "../shared/styles";
import styled from "styled-components";

interface Props {
  title: string;
  isShownBackButton?: boolean;
  onBack: () => void;
}

export const Header = (props: Props) => {
  return (
    <Wrapper $isShownBackButton={props.isShownBackButton}>
      <AppTitle>{props.title}</AppTitle>
      {props.isShownBackButton && (
        <Button size="sm" outline onClick={props.onBack}>
          Back
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isShownBackButton?: boolean }>`
  height: 32px;
  display: ${(props) => (props.$isShownBackButton ? "flex" : "block")};
  justify-content: ${(props) =>
    props.$isShownBackButton ? "space-between" : "flex-start"};
  align-items: ${(props) =>
    props.$isShownBackButton ? "center" : "flex-start"};
  text-align: ${(props) => (props.$isShownBackButton ? "left" : "center")};
`;
