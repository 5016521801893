import styled from "styled-components";
import { Header } from "./Header";
import { BaseSyntheticEvent, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Fade } from "reactstrap";

interface Props {
  title: string;
  show: boolean;
  onClose: () => void;
}

export const Modal = ({
  title,
  show,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  if (!show) return null;

  const root = document.getElementById("modal-container");
  if (!root) return null;

  const closeModal = (event: BaseSyntheticEvent) => {
    if (event) {
      if (event.target !== event.currentTarget) return;
      event.preventDefault();
    }

    onClose();
  };

  return createPortal(
    <Wrapper onClick={closeModal}>
      <StyledModal>
        <Header title={title} isShownBackButton onBack={onClose}></Header>
        <Body>{children}</Body>
      </StyledModal>
    </Wrapper>,
    root
  );
};

const Wrapper = styled(Fade)`
  z-index: 1000;
  position: fixed;
  max-width: 100%;
  top: 20px;
  left: 23px;
  right: 24px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledModal = styled.div`
  margin-top: -48px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 2px 4px 4px 0px #7749f830;
  border: 1px solid #5227cc;
  padding: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
