import { Badge, Button, Input } from "reactstrap";
import styled from "styled-components";

interface Props {
  name: string;
  step?: string;
  type: "habit" | "task";
  selectable?: boolean;
  active: boolean
  completed?: boolean
  showCheckbox?: boolean
  onDelete?: () => void;
  onEdit?: () => void;
  onSelect?: () => void
  onComplete?: () => void
}

export const Task = ({ name, step, type, showCheckbox, selectable, completed, onComplete, active, onDelete, onEdit, onSelect }: Props) => {

  const handleClick = () => {
    if (!selectable || !onSelect) return
    return onSelect()
  }

  return (
    <Wrapper $selectable onClick={handleClick}>
      
      <div style={{width: '100%'}}>
        <Content $type={type}>
          <Body $active={selectable ? active : false} $selectable={selectable} $type={type}>
            <Row>
              <StyledBadge $type={type}>{type}</StyledBadge>
              { step && <StyledStepBadge>{step}</StyledStepBadge>}
            </Row>
            <Name>{name}</Name>
          </Body>
        </Content>

        {!selectable && (
          <Footer>
            <LittleButton style={{color: '#6C757D'}} outline size="sm" onClick={onEdit}>Edit</LittleButton>
            <LittleButton style={{color: '#DC3545'}} color="danger" outline size="sm" onClick={onDelete}>Delete</LittleButton>
          </Footer>
        )}
      </div>
      
      { showCheckbox && (
        <CheckZone>
          <StyledInput type="checkbox" onChange={onComplete} checked={completed} />
        </CheckZone>
      )}
    </Wrapper>
  );
};

const CheckZone = styled.div`
  margin-left: 12px;
`

const Wrapper = styled.div<{ $selectable: boolean }>`
  display: flex;
  flex-direction: row;

  cursor: ${props => props.$selectable ? 'cursor' : 'default'};
  user-select: none;
`;

const Content = styled.div<{ $type: 'habit' | 'task' }>`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 4px  ;
    width: 1px;
    height: calc(100% - 8px);
    background-color: ${(props) => props.$type === "task" ? "#28a745" : "#17A2B8"}
  }
`

const Body = styled.div<{ $type: 'habit' | 'task', $active: boolean, $selectable?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  margin-left: 4px;
  border-radius: 4px;
  background-color: ${(props) => props.$active 
    ? props.$type === "task" ? "#EAFFE2" : "#E1FBFF"
    : '#fff'
  };
  transition: all .3s ease;

  /* @media screen and (max-width: 700px) {
    &:hover {
      background-color: ${props => props.$selectable 
        ? props.$type === "task" ? "#EAFFE2" : "#E1FBFF"
        : 'unset'
      }
    }
  } */
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const Name = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const LittleButton = styled(Button)`
  font-size: 10px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 12px;

  &:hover {
    color: #fff !important;
  }
`

const StyledBadge = styled(Badge)<{ $type: "habit" | "task" }>`
  text-transform: capitalize;
  background-color: ${(props) =>
    props.$type === "task" ? "#28a745" : "#17A2B8"} !important;
`;

const StyledStepBadge = styled(Badge)`
  background-color: #7749F8 !important;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 200px; 
  white-space: nowrap;
`;

const StyledInput = styled(Input)`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 7px;
  background-size: 28px;
  border: 1px solid #7749F8;
  margin-top: 40px;

  &:checked {
    background-color: #7749F8;
  }
`
