import styled from "styled-components";
import { Demo } from "./components/Demo";
import mockup from "./assets/mockup.png";
import { ReactComponent as Logo } from "./assets/logo.svg";
import appStore from "./assets/app-store.png";
import playMarket from "./assets/google-play.png";
import useWindowSize from "./hooks/useWindowSize";

function App() {
  const windowSize = useWindowSize()

  return (
    <Root>
      <Content>
        <Overview>
          <TeamName>
            <Logo style={{ marginRight: "16px" }} />
            <span>Sparonix Team</span>
          </TeamName>

          <Title>Create your way to success with AI Goal Planner</Title>

          {/* <MarketButtons>
          <Button>
            <img src={playMarket} alt="Play Market Logo" />
            Download for Android
          </Button>
          <Button>
            <img src={appStore} alt="App Store Logo" />
            Download for IOS
          </Button>
        </MarketButtons> */}
        </Overview>

        { windowSize.width >= 700 ? (
          <IphoneMockup>
            <MockupBack />

            <StyledMockup src={mockup} alt="mockup" />

            <DemoWrapper>
              <Demo />
            </DemoWrapper>

            <div id="modal-container"></div>
          </IphoneMockup>
        ) : (
            <IphoneMockup>
              <DemoMobile>
                <Demo />
              </DemoMobile>
            </IphoneMockup>
        )}

      </Content>
    </Root>
  );
}

const IphoneMockup = styled.div`
  position: relative;
  transform: scale(1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMockup = styled.img`
  position: relative;
  width: 420px;
`;

const DemoWrapper = styled.div`
  position: absolute;
  top: 48px;
  width: calc(100% - 48px);
  height: calc(100% - 68px);
  border-radius: 42px;
  overflow: hidden;
`;

const DemoMobile = styled.div`
  position: absolute;
  transform: scale(1);
  width: 100vw;
  height: 100vh;
`

const MockupBack = styled.div`
  position: absolute;
  width: calc(100% - 48px);
  height: calc(100% - 20px);
  border-radius: 42px;
  overflow: hidden;
  background-color: #fff;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(180deg, #5d24ff -61.39%, #dbd0fd 183.99%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 120px;
  height: 100%;

  @media screen and (max-width: 700px) {
    padding-bottom: 0;
  } 
`;

const Overview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
`;

const TeamName = styled.span`
  display: inline-block;
  margin-bottom: 34px;
  font-family: "Montserrat", sans-serif;
  vertical-align: middle;
  color: #fff;
  font-size: 30px;
  font-weight: 800;

  span {
    vertical-align: middle;
  }
`;

const MarketButtons = styled.div`
  display: flex;
  margin-top: 32px;

  & > *:first-child {
    margin-right: 24px;
  }
`;

const Button = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  &:hover {
    background-color: #f3f3f3;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 55px;
  font-weight: 700;

  @media screen and (max-width: 700px) {
    font-size: 34px;
    padding: 24px;
  } 
`;

export default App;
