import { Fade } from "reactstrap";
import styled from "styled-components";

export const AppTitle = styled.h6<{ center?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  text-align: ${(props) => props.center ? 'center' : 'left'};
`;

// LOADER

export const LoaderWrapper = styled(Fade)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoaderTitle = styled.span`
  margin-top: 18px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(var(--bs-primary-rgb));
`;

export const Loader = styled.span`
  margin-top: -48px;
  width: 48px;
  height: 48px;
  border: 5px solid rgb(var(--bs-primary-rgb));
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;

  &:after {
    content: "";
    position: absolute;
    width: 48px;
    height: 48px;
    border: 5px solid rgb(var(--bs-primary-rgb));
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }
`;

export const GoalName = styled.span`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;