import styled from "styled-components"
import { GoalName } from "../shared/styles"
import { ReactComponent as CheckIcon} from '../assets/check.svg'
import { IStep } from "../models/IStep"
import { Task } from "./Task"
import { Button } from "reactstrap"
import { useState } from "react"
import { Screen } from "../models/Screen"

interface Props {
  goal: string
  steps: IStep[]
  addNewTasksToPlan: (ids: string[]) => void
  setScreen: (screen: Screen) => void;
}

export const PlannerScreen = (props: Props) => {
  const [selectedTasks, setSelectedTasks] = useState<string[]>([])
  const [selectedHabits, setSelectedHabits] = useState<string[]>([])

  const handleSelectTask = (id: string) => {
    if (selectedTasks.includes(id)) {
      setSelectedTasks(selectedTasks.filter(taskId => taskId !== id))
    } else {
      setSelectedTasks([...selectedTasks, id])
    }
  }

  const handleSelectHabit = (id: string) => {
    if (selectedHabits.includes(id)) {
      setSelectedHabits(selectedHabits.filter(taskId => taskId !== id))
    } else {
      setSelectedHabits([...selectedHabits, id])
    }
  }

  const addTasksToPlan = () => {
    props.addNewTasksToPlan([...selectedTasks, ...selectedHabits])
    setSelectedTasks([])
    setSelectedHabits([])
    props.setScreen(Screen.Workspace)
  }

  return (
    <Wrapper>
      <GoalName>{props.goal}</GoalName>
      
      <HintWrapper>
        <Hint>
          <CheckIcon />
          <span>Click to select task or habit</span>
        </Hint>
        <Counter>Selected: {selectedTasks.length + selectedHabits.length}</Counter>
      </HintWrapper>

      <Plan>
        {props.steps.map(step => (step.habits.length || step.tasks.length) &&
          <Step key={step.id}> 
            <StepName>{step.title}</StepName>
            <TaskList>
              {step.tasks.map(task => task.isDeclined && (
                <Task
                  key={task.id}
                  active={selectedTasks.includes(task.id)}
                  type="task"
                  name={task.title}
                  selectable
                  onSelect={() => handleSelectTask(task.id)}
                />
              ))}
              {step.habits.map(task => task.isDeclined && (
                <Task
                  key={task.id}
                  active={selectedHabits.includes(task.id)}
                  type="habit"
                  name={task.title}
                  selectable
                  onSelect={() => handleSelectHabit(task.id)}
                />
              ))}
            </TaskList>
          </Step>
        )}
      </Plan>

      <Footer>
        <Button block color="primary" onClick={addTasksToPlan}>Add to goal plan</Button>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Footer = styled.div`
  z-index: 1;
  padding: 8px 24px 32px;
`

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 24px;
`

const HintWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  color: #636464;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
`

const Counter = styled.span``

const Step = styled.div`
  margin-bottom: 24px;
`
const TaskList = styled.div`
  & > * {
    margin-top: 12px;
  }
`
const StepName = styled.div`
  padding: 6px 8px;
  width: 100%;
  border-radius: 4px;
  background: #7749F8;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
`

const Hint = styled.p`
  margin: 12px 0;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    margin-right: 6px;
    vertical-align: middle;
  }
`