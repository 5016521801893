import styled from "styled-components";
import { IStep } from "../models/IStep";
import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import { Task } from "./Task";
import { Modal } from "./Modal";
import { GoalName } from "../shared/styles";
import { Screen } from "../models/Screen";
import { ITask } from "../models/ITask";

interface Props {
  goal: string;
  steps: IStep[];
  deleteTask: (stepId: string, taskId: string, type: 'task' | 'habit') => void;
  completeTask: (stepId: string, taskId: string, type: 'task' | 'habit') => void;
  setScreen: (screen: Screen) => void;
  setGoal: (goal: string) => void;
}

interface IExtTask extends ITask {
  stepId: string
  type: "task" | "habit"
  isCompleted: boolean

}

export const WorkspaceScreen = ({
  goal,
  steps,
  deleteTask,
  setScreen,
  setGoal,
  completeTask,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState<IExtTask[]>([])

  const generateNewPlan = () => {
    setScreen(Screen.PlanCreation)
    setGoal('')
  }

  const getStepNameById = (id: string) => {
    const foundStep = steps.find(step => step.id === id)
    if (!foundStep) return ''

    return foundStep.title
  }

  useEffect(() => {
    const tasks = steps
      .map(step => step.tasks.map(task => ({ ...task, stepId: step.id, type: 'task' })))
      .flat()

    const habits = steps
      .map(step => step.habits.map(task => ({ ...task, stepId: step.id, type: 'habit' })))
      .flat()

    setTasks([...tasks, ...habits] as IExtTask[])
  }, [steps])

  return (
    <>
      <Wrapper>
        <GoalName>{goal}</GoalName>

        <Content>
          {tasks.map((task) => !task.isDeclined && (
            <Task
              name={task.title}
              key={task.id}
              type={task.type}
              step={getStepNameById(task.stepId)}
              active
              completed={task.isCompleted}
              showCheckbox
              onDelete={() => deleteTask(task.stepId, task.id, task.type)}
              onEdit={() => setShowModal(true)}
              onComplete={() => completeTask(task.stepId, task.id, task.type)}
            />
          ))}
        </Content>

        <Footer>
          <Button 
            color="primary" 
            block 
            onClick={generateNewPlan}
          >
            Generate new goal
          </Button>
        </Footer>
      </Wrapper>

      <Modal
        show={showModal}
        title="Samurai way"
        onClose={() => setShowModal(false)}
      >
        <ModalText>This feature is not available in the demo version</ModalText>
        <Button
          color="primary"
          style={{ alignSelf: "center" }}
          onClick={() => setShowModal(false)}
        >
          Download app
        </Button>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 24px;

  & > * {
    margin-bottom: 24px;
  }
`;

const ModalText = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Footer = styled.div`
  z-index: 1;
  padding: 8px 24px 32px;
`