import { useEffect, useState } from "react";
import { Screen } from "../models/Screen";
import styled from "styled-components";
import { axios } from "../utils/agent";
import { PlanCreationScreen } from "./PlanCreationScreen";
import { Loader, LoaderTitle, LoaderWrapper } from "../shared/styles";
import { WorkspaceScreen } from "./WorkspaceScreen";
import { IStep } from "../models/IStep";
import { PlannerScreen } from "./PlannerScreen";
import { ReactComponent as TaskListIcon} from '../assets/task-list.svg'
import { ReactComponent as EditIcon} from '../assets/edit.svg'
import { IGeneratedPlanResponse } from "../models/IGeneratedPlanResponse";
import { ITask } from "../models/ITask";


export const Demo = () => {
  const [screen, setScreen] = useState<Screen>(Screen.PlanCreation);
  const [isGenerating, setIsGenerating] = useState(false);
  const [goal, setGoal] = useState("");
  const [steps, setSteps] = useState<IStep[]>([]);

  const generatePlan = async (goal: string) => {
    setIsGenerating(true);
    setGoal(goal);

    axios.post<IGeneratedPlanResponse>('goals/generate-plan', { goalName: goal })
      .then(({ data }) => {
        data.steps = data.steps.map(step => ({ 
          ...step, 
          title: step.title.substring(step.title.indexOf(":") + 1).trim(),
          tasks: step.tasks.map(task => ({ ...task, isCompleted: false })),
          habits: step.habits.map(task => ({ ...task, isCompleted: false }))
        }))

        const plan = createPlan(data.steps)

        setSteps(plan);
        setScreen(Screen.Workspace);
      })
      .finally(() => setIsGenerating(false));
  };

  const getShuffled = (array: any[]) => {
    return [...array].sort(() => 0.5 - Math.random());
  }

  const createPlan = (steps: IStep[]) => {
    const data = steps.map(step => {
      const tasks = [...step.tasks]
      const shuffledTaks: ITask[] = getShuffled(tasks)
      const selectedTasks = shuffledTaks.map((task, index) => ({ ...task, isDeclined: index >= 2 }))

      const habits = [...step.habits]
      const shuffledHabit: ITask[] = getShuffled(habits)
      const selectedHabits = shuffledHabit.map((task, index) => ({ ...task, isDeclined: index >= 2 }))

      return { ...step, tasks: selectedTasks, habits: selectedHabits }
    })

    const ids = data
      .map(step => ([...step.tasks, ...step.habits]
      .filter(task => !task.isDeclined)))
      .flat()
      .map(task => task.id)

    try {
      axios.post('tasks/submit-range', { Tasks: ids })
    } catch {}
    
    return data
  }

  const addNewTasksToPlan = (ids: string[]) => {
    const updatedSteps = steps.map((step) => {
      const tasks = step.tasks.map(task => ({ ...task, isDeclined: ids.includes(task.id) ? true : task.isDeclined}))
      const habits = step.habits.map(task => ({ ...task, isDeclined: ids.includes(task.id) ? true : task.isDeclined }))
      
      return { ...step, tasks, habits }
    })

    setSteps(updatedSteps)
  }

  const completeTask = (stepId: string, taskId: string, type: 'task' | 'habit') => {
    const foundStep = steps.find((step) => step.id === stepId);
    if (!foundStep) return;

    const key = type === 'task' ? 'tasks' : 'habits'

    const updatedSteps =  steps.map(step => {
      const tasks = step[key].map(task => {
        if (task.id === taskId) {
          return { ...task, isCompleted: !task.isCompleted}
        }

        return task
      })

      return { ...step, [key]: tasks }
    })

    setSteps(updatedSteps);
  }

  const deleteTask = async (stepId: string, taskId: string, type: 'task' | 'habit') => {
    const foundStep = steps.find((step) => step.id === stepId);
    if (!foundStep) return;

    const key = type === 'task' ? 'tasks' : 'habits'
    
    try {
      axios.post(`${key}/remove/${taskId}`)
    } catch (err) {}

    setSteps(
      steps.map(step => {
        const tasks = step[key].map(task => ({ ...task, isDeclined: taskId === task.id || task.isDeclined}))
        return { ...step, [key]: tasks }
      })
    );
  };

  if (isGenerating) {
    return (
      <DemoStyled>
        <LoaderWrapper>
          <Loader />
          <LoaderTitle>Generating...</LoaderTitle>
        </LoaderWrapper>
      </DemoStyled>
    );
  }

  const showHeaderAndFooter = screen === Screen.Workspace || screen === Screen.Planner

  return (
    <DemoStyled>
      {showHeaderAndFooter && (
        <Tabs>
          <Tab 
            active={screen === Screen.Workspace}
            onClick={() => setScreen(Screen.Workspace)}
          >
            <TaskListIcon />
            <span>Task list</span>
          </Tab>
          <Tab
            active={screen === Screen.Planner}
            onClick={() => setScreen(Screen.Planner)}
          >
            <EditIcon />
            <span>Planner</span>
          </Tab>
        </Tabs>
      )}
 
      <Content>
        {screen === Screen.PlanCreation && (
          <PlanCreationScreen 
            defaultValue={goal}
            generatePlan={generatePlan}
          />
        )}
        {screen === Screen.Workspace && (
          <WorkspaceScreen
            goal={goal}
            steps={steps}
            deleteTask={deleteTask}
            completeTask={completeTask}
            setScreen={setScreen}
            setGoal={setGoal}
          />
        )}
        {screen === Screen.Planner && (
          <PlannerScreen
            steps={steps}
            goal={goal}
            setScreen={setScreen}
            addNewTasksToPlan={addNewTasksToPlan}
          />
        )}
      </Content>
    </DemoStyled>
  );
};

const DemoStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  margin-top: 24px;
  flex: 1;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 24px 0;
`

const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${props => props.active ? '#7749F8' : 'transparent'};

  span {
    display: inline-block;
    vertical-align: middle;
    color: #343A40;
  }

  svg {
    vertical-align: middle;
    margin-right: 4px;
    color: ${props => props.active ? '#7749F8' : '#343A40'};
  }
`