import styled from "styled-components";
import { Input, Button, Modal } from "reactstrap";
import { useEffect, useState } from "react";
import { AppTitle } from '../shared/styles'


interface Props {
  generatePlan: (goal: string) => void;
  defaultValue: string;
}

export const PlanCreationScreen = (props: Props) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [showModal, setShowModal] = useState(false);

  let goal = "";

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    goal = value;
    setIsDisabledButton(value.split("").length <= 3);
  };

  useEffect(() => {
    setIsDisabledButton(props.defaultValue.split("").length <= 3);
  }, [props.defaultValue]);

  return (
    <>
      <AppTitle style={{marginTop: '24px'}} center={true}>Samurai way: AI Goal Planner</AppTitle>
      <Wrapper>
        <Title>Create a step-by-step plan for your goal</Title>
        <StyledInput
          defaultValue={props.defaultValue}
          placeholder="Enter your goal here"
          onInput={handleInput}
        />
        <Button
          color="primary"
          disabled={isDisabledButton}
          onClick={() => props.generatePlan(goal)}
        >
          Generate a plan
        </Button>
      </Wrapper>

      <Modal
        show={showModal}
        title="Samurai way"
        onClose={() => setShowModal(false)}
      >
        <ModalTitle>Your goal is too simple.</ModalTitle>
        <ModalText>Would you like to turn it into a task?</ModalText>

        <ModalFooter>
          <Button color="primary">Yes, let's turn it into a task</Button>
          <Button
            color="primary"
            outline
            onClick={() => {
              setShowModal(false);
              props.generatePlan(goal);
            }}
          >
            No, I want it to be a goal
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -48px;
  height: 100%;
  padding: 0 24px;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 40px;
`;

const StyledInput = styled(Input)`
  width: 240px;
  margin-bottom: 40px;
`;

const ModalTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const ModalText = styled.span`
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

const ModalFooter = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  align-self: center;

  & > *:first-child {
    margin-bottom: 12px;
  }
`;
